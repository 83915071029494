
import { Component, Vue } from 'vue-property-decorator';
import TimelinePage from './Timeline/TimelinePage.vue';
import AdvisoryOverviewPage from './AdvisoryOverview/AdvisoryOverviewPage.vue';

@Component({
  components: {
    TimelinePage,
    AdvisoryOverviewPage,
  },
})
export default class CustomerOverviewPageTimeline extends Vue {
  get isAdvisory() {
    return this.$store.getters.isAdvisoryUser;
  }
}
