import { Signal } from 'signals';
import { Canvas } from '../Canvas';
import { Point } from '../Point';
import { Rectangle } from '../Rectangle';
import { Series } from '../Series';
import { Axis } from '../Axis';
import { Transformer } from '../Transformer';

interface dragOptions {
  mainSize: number;
  offset: Point;
  vpOffset: Pick<Rectangle, 'x1' | 'x2' | 'y1' | 'y2'>;
}

export class Drag {
  type: 'default';
  enabledSeriesData: { x: number; y: number; label: string }[];
  canvas: Canvas;
  series: Series;
  xAxis: Axis;
  yAxis: Axis;
  _options: dragOptions = {
    mainSize: 0,
    offset: new Point(0, 0),
    vpOffset: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
    },
  };
  hoveredPoint: Point | undefined;
  hoveredPointIndex: number | undefined;
  draggedPoint: Point | undefined;
  draggedPointIndex: number | undefined;
  _lastUpdatedIndexes: [number, number] | undefined;
  onHoverEnter: Signal;
  onHoverLeave: Signal;
  onIndexUpdated: Signal;

  constructor(
    type: Drag['type'],
    canvas: Canvas,
    series: Series,
    xAxis: Axis,
    yAxis: Axis,
    enabledSeriesData: Drag['enabledSeriesData']
  ) {
    this.type = type;
    this.canvas = canvas;
    this.series = series;
    this.xAxis = xAxis;
    this.yAxis = yAxis;
    this.enabledSeriesData = enabledSeriesData;
    this.canvas.mouseMoved.add(this.onMouseMoved.bind(this));
    this.canvas.mouseDown.add(this.onMouseGrab.bind(this));
    this.canvas.mouseUp.add(this.onMouseDrop.bind(this));
    this.canvas.mouseOuted.add(this.onMouseDrop.bind(this));
    this.onHoverEnter = new Signal();
    this.onHoverLeave = new Signal();
    this.onIndexUpdated = new Signal();
    this.bindSignals();
  }

  bindSignals() {
    this.onHoverEnter.add(() => {
      this.canvas.container.style.cursor = 'grab';
    });

    this.onHoverLeave.add(() => {
      this.canvas.container.style.cursor = 'auto';
    });
  }

  onMouseMoved() {
    if (this.draggedPoint && this.draggedPointIndex !== undefined) {
      this.updateSeries(this.draggedPointIndex);
      return;
    }

    const [newHoveredPoint, index] = this.getHoveredPoint();

    if (!this.hoveredPoint && newHoveredPoint) {
      this.hoveredPoint = newHoveredPoint;
      this.hoveredPointIndex = index;
      this.onHoverEnter.dispatch();
      return;
    }

    if (this.hoveredPoint && !newHoveredPoint) {
      this.hoveredPoint = newHoveredPoint;
      this.hoveredPointIndex = index;
      this.onHoverLeave.dispatch();
      return;
    }
  }

  onMouseGrab() {
    if (this.hoveredPoint) {
      this.draggedPoint = this.hoveredPoint;
      this.draggedPointIndex = this.hoveredPointIndex;
      this.canvas.container.style.cursor = 'grabbing';
    }
  }

  onMouseDrop() {
    this.draggedPoint = undefined;
    this.canvas.container.style.cursor = 'auto';
    if (this.draggedPointIndex !== undefined) {
      this.updateSeries(this.draggedPointIndex);
    }
    this.draggedPointIndex = undefined;
    this.hoveredPoint = undefined;
    this.hoveredPointIndex = undefined;
  }

  getHoveredPoint(): [Point | undefined, number | undefined] {
    const { mainSize } = this._options;
    let hoveredPoint: Point | undefined;
    let index: number | undefined;
    this.dragCoords.forEach((p, ind) => {
      if (p.findDist(this.mousePlotCoords) <= mainSize) {
        if (
          !(
            hoveredPoint &&
            hoveredPoint.findDist(this.mousePlotCoords) < p.findDist(this.mousePlotCoords)
          )
        ) {
          hoveredPoint = p;
          index = ind;
        }
      }
    });
    return [hoveredPoint, index];
  }

  get dragCoords() {
    const { viewport: vp } = this.canvas;
    const { offset, vpOffset } = this._options;
    const rect = new Rectangle(
      vp.x1 + vpOffset.x1,
      vp.y1 + vpOffset.y1,
      vp.x2 + vpOffset.x2,
      vp.y2 + vpOffset.y2
    );
    return this.series.plotDataArr.map(
      (point) => new Point(point.x + offset.x, Math.round((rect.y2 - rect.y1) * 0.5) + rect.y1)
    );
  }

  get mousePlotCoords(): Point {
    const { mouseCoords, top, left } = this.canvas;
    return new Point(mouseCoords.x + left, mouseCoords.y + top);
  }

  getDraggedSeriesPoint(index: number) {
    return new Point(this.series.seriesData[0][index], this.series.seriesData[1][index]);
  }

  getSeriesCoords(point: Point) {
    const { viewport } = this.canvas;
    const seriesX = this.xAxis.min + (point.x * this.xAxis.length) / viewport.width;
    const seriesY = this.yAxis.max - (point.y * this.yAxis.length) / viewport.height;
    return new Point(seriesX, seriesY);
  }

  getSeriesTransformedCoords(point: Point) {
    const { viewport } = this.canvas;
    const transformer = new Transformer();
    return transformer.getVeiwportCoord(viewport, this.axisRect, point);
  }

  setOptions(options: Partial<dragOptions>) {
    this._options = { ...this._options, ...options };
    return this;
  }

  get axisRect(): Rectangle {
    return new Rectangle(this.xAxis.min, this.yAxis.min, this.xAxis.max, this.yAxis.max);
  }

  updateSeries(index: number) {
    const newPoint = this.getSeriesTransformedCoords(this.mousePlotCoords);
    let closestInd = 0;
    let closestEnabled = this.enabledSeriesData[closestInd];
    this.enabledSeriesData.forEach((item, ind) => {
      if (Math.abs(item.x - newPoint.x) < Math.abs(closestEnabled.x - newPoint.x)) {
        closestInd = ind;
        closestEnabled = item;
      }
    });
    const shouldUpdate =
      !this._lastUpdatedIndexes ||
      (this._lastUpdatedIndexes &&
        !(this._lastUpdatedIndexes[0] === index && this._lastUpdatedIndexes[1] == closestInd));

    if (!shouldUpdate) return;

    const x = [...this.series.seriesData[0]];
    const y = [...this.series.seriesData[1]];
    const lables = [...(this.series.plotLabels || [])];
    x.splice(index, 1, closestEnabled.x);
    y.splice(index, 1, closestEnabled.y);
    lables.splice(index, 1, closestEnabled.label);
    this.series.replaceSeriesData([x, y], true, lables);
    this._lastUpdatedIndexes = [index, closestInd];
    this.onIndexUpdated.dispatch(index, closestInd);
  }

  _draw(point: Point) {
    const { ctx } = this.canvas;
    const { mainSize } = this._options;
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.arc(point.x, point.y, mainSize, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.stroke();
  }
}
