var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"peer-benchmarking__legend"},[_c('div',{staticClass:"peer-benchmarking__legend-item"},[_vm._v(_vm._s(_vm.companyTitle))]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"peer-benchmarking__legend-item"},[_vm._v(" low"),_c('br'),_vm._v(" risk ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"peer-benchmarking__legend-item"},[_vm._v(" medium"),_c('br'),_vm._v(" risk ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"peer-benchmarking__legend-item"},[_vm._v(" medium/high"),_c('br'),_vm._v(" risk ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"peer-benchmarking__legend-item"},[_vm._v(" high"),_c('br'),_vm._v(" risk ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"peer-benchmarking__legend-item"},[_vm._v(" highest"),_c('br'),_vm._v(" risk ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"peer-benchmarking__legend-item"},[_vm._v(" number of companies"),_c('br'),_vm._v(" per risk group ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"peer-benchmarking__legend-item"},[_vm._v(" annual expected frequency"),_c('br'),_vm._v(" for large breach ")])
}]

export { render, staticRenderFns }