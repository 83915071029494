
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Chart } from '@/libs/chart-lib';
import { createChart } from './early-warning-timeline-bar-chart-config';
import { EarlyWarningItem, EarlyWarningModule } from '@/store/modules/company-tabs/early-warning';
import { getEarlyWarningColorByValue } from '../../helpers/index';
import { Tooltip, tooltipObject } from '@/libs/chart-lib/classes/tooltip/Tooltip';

@Component
export default class EarlyWarningTimelineChart extends Vue {
  @Prop() readonly year!: number;
  @Prop() readonly peerGroup!: EarlyWarningItem['PeerGroup'];
  private chart?: Chart;

  mounted() {
    this.chart = createChart({
      container: this.$refs.chartContainer as HTMLElement,
      dataList: this.chartDataList,
      rangeYear: this.year,
    });

    if (this.chart) {
      this.chart.tooltipsDataIndexUpdated.add((payload) => {
        const tooltip = payload.tooltip as Tooltip;
        const tt = payload.tt as tooltipObject;
        try {
          const date = tooltip.labels?.[tt.ind] as string;
          this.onToolTipChange(date);
        } catch (error) {
          // skip
        }
      });
    }
  }

  @Emit()
  onToolTipChange(dateLabel: string) {
    return dateLabel;
  }

  activated() {
    window.dispatchEvent(new Event('resize'));
  }

  beforeDestroy() {
    delete this.chart;
  }

  getColorByValue(value: number) {
    if (value <= 0) return '#e1e1e1';
    return getEarlyWarningColorByValue(value);
  }

  get dataList() {
    switch (this.peerGroup) {
      case 'sector':
        return EarlyWarningModule.sectorMap;
      case 'industry':
        return EarlyWarningModule.industryMap;
    }
  }

  get chartDataList() {
    return this.dataList[this.year]
      .map(({ chTimeItem, MitreScore }) => {
        return {
          date: chTimeItem,
          value: MitreScore.value,
          color: this.getColorByValue(MitreScore.color),
        };
      })
      .filter(({ value }) => Boolean(value));
  }
}
