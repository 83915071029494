
import { Component, Vue, Prop } from 'vue-property-decorator';
import EarlyWarningTimelineChart from './EarlyWarningTimelineChart/EarlyWarningTimelineChart.vue';
import EarlyWarningTable from './EarlyWarningTable.vue';
import { EarlyWarningItem, EarlyWarningModule } from '@/store/modules/company-tabs/early-warning';
import CollapseContent from './CollapseContent.vue';

@Component({
  components: {
    EarlyWarningTimelineChart,
    CollapseContent,
    EarlyWarningTable,
  },
})
export default class EarlyWarningDashboard extends Vue {
  @Prop() year!: number;
  @Prop({ default: false }) showYear!: boolean;
  public peerGroup: EarlyWarningItem['PeerGroup'] = 'industry';
  public hoveredLabel: string = '';

  get peerGroupOptions(): { label: string; value: EarlyWarningItem['PeerGroup'] }[] {
    return [
      {
        label: `Direct peer group (${EarlyWarningModule.industryMap[this.year].length})`,
        value: 'industry',
      },
      {
        label: `Large peer group (${EarlyWarningModule.sectorMap[this.year].length})`,
        value: 'sector',
      },
    ];
  }

  setHoveredLabel(dateString: string) {
    this.hoveredLabel = dateString;
  }

  reduce(item: { label: string; value: EarlyWarningItem['PeerGroup'] }) {
    return item.value;
  }
}
