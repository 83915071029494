
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CollapseContent extends Vue {
  @Prop({ default: false }) noPadding!: boolean;

  isCollapsed: boolean = true;

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
}
