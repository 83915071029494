
import TriangleTextBlock from '@/components/TriangleTextBlock.vue';
import { EarlyWarningItem, EarlyWarningModule } from '@/store/modules/company-tabs/early-warning';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getEarlyWarningColorByValue } from '../helpers';

@Component({ components: { TriangleTextBlock } })
export default class EarlyWarningTable extends Vue {
  @Prop() peerGroup!: EarlyWarningItem['PeerGroup'];
  @Prop() year!: number;
  @Prop({ default: '' }) hoveredLabel!: string;
  public showValues: boolean = false;

  get dataList() {
    switch (this.peerGroup) {
      case 'sector':
        return EarlyWarningModule.sectorMap;
      case 'industry':
        return EarlyWarningModule.industryMap;
    }
  }

  get tableData() {
    return [...this.dataList[this.year]].filter(({ HasSpike }) => HasSpike);
  }

  getCellBgColor(value: number) {
    if (value === 0) return '#fff';
    return getEarlyWarningColorByValue(value);
  }
}
