
import { Component, Vue } from 'vue-property-decorator';
import { Chart } from '@/libs/chart-lib';
import { createChart } from './peers-area-chart-config';
import MicroTrendModule from '@/store/modules/company-tabs/micro-trend';
import { ceilUTCDateToTimestamp } from '../../AdvisoryOverview/utils';

@Component
export default class PeersAreaChart extends Vue {
  private chart?: Chart;

  mounted() {
    if (this.trendList.length > 0) {
      this.chart = createChart({
        container: this.$refs.chartContainer as HTMLElement,
        startTimestamp: this.startTimestamp,
        endTimestamp: this.endTimestamp,
        data: this.trendList.filter(
          (el) => el.date.timestamp >= this.startTimestamp && el.date.timestamp <= this.endTimestamp
        ),
      });
    }
  }

  beforeDestroy() {
    delete this.chart;
  }

  activated() {
    window.dispatchEvent(new Event('resize'));
  }

  // store data
  get trendList(): MicroTrendModule['trendList'] {
    return this.$store.getters.microTrendList;
  }

  get endTimestamp() {
    return this.trendList.slice(-1)[0].date.timestamp;
  }

  get startTimestamp() {
    const date = new Date(this.endTimestamp);
    date.setUTCMonth(date.getUTCMonth() - 11);
    return ceilUTCDateToTimestamp(date);
  }
}
