
import { LossSeverityModule } from '@/store/modules/company-tabs/loss-severity';
import { Component, Vue } from 'vue-property-decorator';
import { LossSeverityColorScheme } from '../utils';

@Component
export default class LossSeverityRiskWidget extends Vue {
  get currentRisk() {
    return LossSeverityModule.currentRiskGroup;
  }

  get textColor() {
    return this.currentRisk && LossSeverityColorScheme[this.currentRisk];
  }
}
