import chroma from 'chroma-js';

const GRADIENT_1 = [
  { color: '#ec5729', value: 0 },
  { color: '#f19537', value: (100 * 1) / 3 },
  { color: '#fada4a', value: (100 * 2) / 3 },
  { color: '#5cc83b', value: 100 },
];

const GRADIENT_2 = [
  { color: '#f00', value: 0 },
  { color: '#ff9a1e', value: 51.5 },
  { color: '#2bb91c', value: 100 },
];

export function getEarlyWarningColorByValue(value: number) {
  const f = chroma
    .scale(GRADIENT_1.map(({ color }) => color))
    .domain(GRADIENT_1.map(({ value }) => value));

  return f(value).hex();
}
